/**
 *  项目的配置文件
 */
export const chatUrl = 'wss://im.cechds.com';//客服地址
export const mUrl = 'https://m.cechds.com/'//移动端网页地址
export const apiUrl = 'https://mall.cechds.com/';//接口请求地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '数智商城';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** 数智商城 *** version-v4.2 *** date-2022-10-13 ***主版本v4.2**/



